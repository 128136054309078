import React, { useState } from 'react';
import queryString from 'query-string';

export default function SignUpForm() {

  const [submitted, setSubmitted] = useState(false);

  const [FNAME, setFNAME] = useState("");
  const [LNAME, setLNAME] = useState("");
  const [EMAIL, setEMAIL] = useState("");
  const [COUNTRY, setCOUNTRY] = useState("");
  const [POSTCODE, setPOSTCODE] = useState("");

  const handleTextChange = (e) => {
    const text = e.target.value;
    if(e.target.name === "FNAME") {
      setFNAME(text);
    }
    if(e.target.name === "LNAME") {
      setLNAME(text);
    }
    if(e.target.name === "EMAIL") {
      setEMAIL(text);
    }
    if(e.target.name === "POSTCODE") {
      setPOSTCODE(text);
    }
    if(e.target.name === "COUNTRY") {
      setCOUNTRY(text);
    }

  }

  const setSubmittedFn = (e) => {

    const formData = {
      FNAME,
      LNAME,
      EMAIL,
      POSTCODE,
      COUNTRY
    };
    const jsonpURL = `https://ownedcollectively.us10.list-manage.com/subscribe/post-json?u=ccfdbf3a2f58128b106c53d05&amp;id=684522f8ec&${queryString.stringify(formData)}&c=?`;

    const script = document.createElement("script");
    script.src = jsonpURL;
    script.async = true;
    document.body.appendChild(script);

    setSubmitted(true);
  }

  return (
    <div className="w-full max-w-xl xl:px-8 xl:w-8/12">
      <div className="bg-white/80 rounded shadow-2xl p-7 sm:p-10">
        {!submitted ?
          <div> 
            <h3 className="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
              Sign-up for updates
            </h3>
            <form
              onSubmit={setSubmittedFn}  
              action="https://ownedcollectively.us10.list-manage.com/subscribe/post?u=ccfdbf3a2f58128b106c53d05&amp;id=684522f8ec" 
              method="post" 
              id="mc-embedded-subscribe-form" 
              name="mc-embedded-subscribe-form" 
              className="validate" 
              target="_blank" 
              noValidate
            >
              <div className="lg:flex lg:flex-row">
                <div className="lg:w-1/2 lg:mr-4 mb-1 sm:mb-2">
                  <label
                    htmlFor="firstName"
                    className="inline-block mb-1 font-medium"
                  >
                    First name
                  </label>
                  <input
                    placeholder="John"
                    required
                    type="text"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="firstName"
                    name="FNAME"
                    onChange={handleTextChange}
                  />
                </div>
                <div className="lg:w-1/2 mb-1 sm:mb-2">
                  <label
                    htmlFor="lastName"
                    className="inline-block mb-1 font-medium"
                  >
                    Last name
                  </label>
                  <input
                    placeholder="Doe"
                    required
                    type="text"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="lastName"
                    onChange={handleTextChange}
                    name="LNAME"
                  />
                </div>
              </div>
              <div className="mb-1 sm:mb-2">
                <label
                  htmlFor="email"
                  className="inline-block mb-1 font-medium"
                >
                  E-mail
                </label>
                <input
                  placeholder="john.doe@example.org"
                  required
                  type="text"
                  className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                  id="email"
                  onChange={handleTextChange}
                  name="EMAIL"
                />
              </div>
              <div className="lg:flex lg:flex-row">
                <div className="lg:w-1/2 lg:mr-4 mb-1 sm:mb-2">
                  <label
                    htmlFor="COUNTRY"
                    className="inline-block mb-1 font-medium"
                  >
                    Country
                  </label>
                  <select 
                    name="COUNTRY" 
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    onChange={handleTextChange}
                  >
                    <option value="UK">UK</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Australia">Australia</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Canada">Canada</option>
                    <option value="China">China</option>
                    <option value="France">France</option>
                    <option value="Germany">Germany</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Italy">Italy</option>
                    <option value="Japan">Japan</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Russia">Russia</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Korea">South Korea</option>
                    <option value="Turkey">Turkey</option>
                    <option value="US">US</option>
                    <option value="EU">EU -other </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="lg:w-1/2 mb-1 sm:mb-2">
                  <label
                    htmlFor="POSTCODE"
                    className="inline-block mb-1 font-medium"
                  >
                    Post/zip code
                  </label>
                  <input
                    placeholder="SE22 9AH"
                    required
                    type="text"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="postCode"
                    onChange={handleTextChange}
                    name="POSTCODE"
                  />
                </div>
              </div>
              <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                <input type="text" name="b_ccfdbf3a2f58128b106c53d05_684522f8ec" tabIndex="-1" value="" readOnly/>
              </div>
              <div className="flex justify-center mt-4 mb-2 sm:mb-4">
                <button
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  className="w-3/5 bg-ocLightCyan inline-flex items-center justify-center px-4 py-2 text-sm font-light tracking-wide text-white transition-colors duration-500 rounded-full shadow-md hover:bg-ocPink hover:text-gray-900 outline-transparent hover:outline hover:outline-ocPink hover:outline-offset-2 hover:outline-2"
                >
                  Subscribe
                </button>
              </div>
              <p className="text-xs text-gray-600 sm:text-sm">
                We respect your privacy. Unsubscribe any time.
              </p>
            </form>
          </div> 
        : 
          <h3 className="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
            You're all signed up to Owned Collectively!
          </h3>
        }
      </div>
    </div>
  );
};