import React, { useState } from 'react';

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <svg
          viewBox="0 0 24 24"
          className={`w-3 text-gray-600 transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          <polyline
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="2,7 12,17 22,7"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

export const Faq = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Unsure?
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-200 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="232db96b-4aa2-422f-9086-5a77996d1df1"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#232db96b-4aa2-422f-9086-5a77996d1df1)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">FAQ</span>
            </span>
          </h2>
          <p className="text-base text-gray-700 md:text-lg ">
            We've spent time writing down the most common questions that come up during the Owned Collectively process.
          </p>
        </div>
        <div className="space-y-4">
          <Item title="How does it work?">
            Simple. You tell us the details of the house you want to buy and what you can afford. Using our campaign wizard we set you up with a page that you can send to friends and family and we can forward to investors about your dream house. When a campaign is complete, if it's been fully funded then we support you through the buying process and set up the legal framework to support a fractionally owned property with you as the main owner. 
          </Item>
          <Item title="Who owns the property?">
            You do! Owned collectivelly operates a model where you are the majority owner with the land registry and the investor group is represented by Owned collectivelly as the minority party on the title.
          </Item>
          <Item title="Can I eventually buy out my investors?">
            Yes! We provide a straightforward way for you to staircase the ownership of your property so if you have a bit of extra cash every year, you can start to buy out your investors.
          </Item>
          <Item title="What happens if I want to sell?">
            As long as you've passed your initial investment time period, you can sell whenever you like. We'll even value your property and see if we can find a buyer with our network before going on the open market. 
          </Item>
        </div>
      </div>
    </div>
  );
};