import React from 'react';

export const Feature = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
            Our mission
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-200 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="27df4f81-c854-45de-942a-fe90f7a300f9"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#27df4f81-c854-45de-942a-fe90f7a300f9)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">Collectively</span>
          </span>{' '}
           helping you own your next home
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          It’s time to start your homeownership journey and plan for your financial future. Start growing your wealth as well as the wealth of the collective that will help you get there, with us, in it, together.
        </p>
      </div>
      <div className="grid max-w-screen-lg gap-8 row-gap-10 mx-auto lg:grid-cols-2">
        <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M7 18Q4.5 18 2.75 16.25Q1 14.5 1 12Q1 9.5 2.75 7.75Q4.5 6 7 6Q8.65 6 10.025 6.825Q11.4 7.65 12.2 9H23V15H21V18H15V15H12.2Q11.4 16.35 10.025 17.175Q8.65 18 7 18ZM7 16Q8.65 16 9.65 14.988Q10.65 13.975 10.85 13H17V16H19V13H21V11H10.85Q10.65 10.025 9.65 9.012Q8.65 8 7 8Q5.35 8 4.175 9.175Q3 10.35 3 12Q3 13.65 4.175 14.825Q5.35 16 7 16ZM7 14Q7.825 14 8.412 13.412Q9 12.825 9 12Q9 11.175 8.412 10.587Q7.825 10 7 10Q6.175 10 5.588 10.587Q5 11.175 5 12Q5 12.825 5.588 13.412Q6.175 14 7 14ZM7 12Q7 12 7 12Q7 12 7 12Q7 12 7 12Q7 12 7 12Q7 12 7 12Q7 12 7 12Q7 12 7 12Q7 12 7 12Z"/></svg>
            </div>
          </div>
          <div>
            <h6 className="mb-3 text-xl font-bold leading-5">Home Buyers</h6>
            <p className="mb-3 text-sm text-gray-900">
              It's simple, you choose the percent(%) of the property you want to own (or can simply afford). Then invite family, friends, and/or professional property investors to form an ownership group that will acquire the remaining percentage of that property. The ownership contribution, combined with your deposit creates your overall down payment.
            </p>
            <a
              href="/"
              aria-label=""
              className="invisible inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M13 11H19.95Q19.575 8.25 17.663 6.337Q15.75 4.425 13 4.05ZM11 19.95V4.05Q7.975 4.425 5.988 6.687Q4 8.95 4 12Q4 15.05 5.988 17.312Q7.975 19.575 11 19.95ZM13 19.95Q15.75 19.6 17.675 17.675Q19.6 15.75 19.95 13H13ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12ZM12 22Q9.925 22 8.1 21.212Q6.275 20.425 4.925 19.075Q3.575 17.725 2.788 15.9Q2 14.075 2 12Q2 9.925 2.788 8.1Q3.575 6.275 4.925 4.925Q6.275 3.575 8.1 2.787Q9.925 2 12 2Q14.075 2 15.887 2.787Q17.7 3.575 19.062 4.938Q20.425 6.3 21.212 8.113Q22 9.925 22 12Q22 14.05 21.212 15.875Q20.425 17.7 19.075 19.062Q17.725 20.425 15.9 21.212Q14.075 22 12 22Z"/></svg>
            </div>
          </div>
          <div>
            <h6 className="mb-3 text-xl font-bold leading-5">
              Property Investors
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              You may be a professional property investor, or just want to diversify your portfolio, or a parent looking to help your kids get on the property ladder. Whichever situation you find yourself in, our platform will match you to a property that's a great fit for your overall portfolio.
            </p>
            <a
              href="/"
              aria-label=""
              className="invisible inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" stroke="currentColor"><path d="M4 21V11.625L2.2 13L1 11.4L4 9.1V6H6V7.575L12 3L23 11.4L21.8 12.975L20 11.625V21ZM6 19H11V15H13V19H18V10.1L12 5.525L6 10.1ZM4 5Q4 3.75 4.875 2.875Q5.75 2 7 2Q7.425 2 7.713 1.712Q8 1.425 8 1H10Q10 2.25 9.125 3.125Q8.25 4 7 4Q6.575 4 6.287 4.287Q6 4.575 6 5ZM6 19H11H13H18H12H6Z"/></svg>
            </div>
          </div>
          <div>
            <h6 className="mb-3 text-xl font-bold leading-5">
              Existing Home Owners
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              You've spent years paying off your house now you'd like to see some of those capital gains for yourself. We will get a current evaluation for your property. You then can choose what percentage you would like to release into the marketplace, making it easy to draw down funds into your bank account.
            </p>
            <a
              href="/"
              aria-label=""
              className="invisible inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="flex flex-col max-w-md sm:mx-auto sm:flex-row">
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M5 17V10H7V17ZM11 17V10H13V17ZM2 21V19H22V21ZM17 17V10H19V17ZM2 8V6L12 1L22 6V8ZM6.45 6H12H17.55ZM6.45 6H17.55L12 3.25Z"/></svg>
            </div>
          </div>
          <div>
            <h6 className="mb-3 text-xl font-bold leading-5">
              Financial Institutions
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Want to offer mortgage services without the hassle of having to manage the systems yourself? We provide a whitelabel solution to allow your customers to collectively fund the mortgage that's right for them.
            </p>
            <a
              href="/"
              aria-label=""
              className="invisible inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};